import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface EvoWatchNetworthUser {
  wallet_value: string;
}

export interface EvoWatchNetworthUserSet {
  errors: unknown;
  EvoWatchNetworthUser: EvoWatchNetworthUser;
}

@Module
export default class NetWorthEvolutionUser extends VuexModule implements EvoWatchNetworthUserSet {
  errors = {};
  EvoWatchNetworthUser = {} as EvoWatchNetworthUser;

  /**
   * Get current Wallet object
   * @returns EvoWatchNetworthUser
   */
  get getNetWorthEvoUser(): EvoWatchNetworthUser {
    return this.EvoWatchNetworthUser;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getEvoUserErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_VAULT_NETWORTH_EVO_USER](data) {
    this.EvoWatchNetworthUser = data;
    this.errors = {};
  }

  @Action
  [Actions.GET_VAULT_NETWORTH_EVO_USER]() {
    ApiService.setHeader();
    ApiService.get("/get-vault-networth-evo-user")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_VAULT_NETWORTH_EVO_USER, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}

