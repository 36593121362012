import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface EvoWatchBenefitUser {
  wallet_value: string;
}

export interface EvoWatchBenefitUserSet {
  errors: unknown;
  EvoWatchBenefitUser: EvoWatchBenefitUser;
}

@Module
export default class NetWorthEvolutionTotal extends VuexModule implements EvoWatchBenefitUserSet {
  errors = {};
  EvoWatchBenefitUser = {} as EvoWatchBenefitUser;

  /**
   * Get current Wallet object
   * @returns EvoWatchBenefitUserSet
   */
  get getEvoBenefitUser(): EvoWatchBenefitUser {
    return this.EvoWatchBenefitUser;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getBenefErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_VAULT_BENEFICE_EVO_USER](data) {
    this.EvoWatchBenefitUser = data;
    this.errors = {};
  }

  @Action
  [Actions.GET_VAULT_BENEFICE_EVO_USER](id) {
    ApiService.setHeader();
    ApiService.get("/get-vault-benefice-evo-user", id)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_VAULT_BENEFICE_EVO_USER, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
}

